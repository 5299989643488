import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const AddCard = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (error) {
      console.log(error);
    } else {
      console.log(paymentMethod);
      // Save payment method ID to local storage
      window.localStorage.setItem("paymentMethodId", paymentMethod.id);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit">Save Card</button>
    </form>
  );
};

export default AddCard;
