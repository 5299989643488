import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { API, key } from "../../config";

// import { navigate } from '@reach/router';
// import {navigate} from '@reac'
import { useNavigate } from "react-router-dom";
import { ERROR_MESS } from "../../constant";
// import { noOfUsers } from "./StripeSlice";
import {
  removeCompantId,
  removeToken,
  setCompantId,
  setToken,
} from "../../utils";
import { SHOW_ERROR } from "../../utils/toastMessages";
const initialState = {
  currentUser: {},
  permissions: [],
  email: {},
  check: true,
  Authenticate: false,
  isFirsLogin: false,
  isFreeRegister: false,
  isFreeExpired: false,
  // userRole: "Manager",
  // userPermissions: ['Plants', 'MaterialMaster', 'BuildArea', 'WorkStation', 'InspectionPlan', 'InspectionLot', 'ApprovalGroups', 'Forms',
  //   'ApprovalForm', 'ApprovalCategory', 'Settings', 'Report'],
  allRoles: [],
  userRole: "",
  userPermissions: [],
  userEmail: "",
  status: "idle",
  sideBarStatus: true,
};

export const getUserData = createAsyncThunk(
  "auth/user",
  async (data, thunkAPI) => {
    try {
      let getPermissions;
      const userDataResponse = await API.auth.user(data.token);

      console.log("userdata", userDataResponse);
      if (userDataResponse.data.success) {
        // thunkAPI.dispatch(getAllRoles());
        const resSuccess = userDataResponse?.data?.success;
        const resData = userDataResponse?.data?.data;
        let userPermissions = [];
        // const parsedObj = JSON.parse(resData?.userPermissions)
        const parsedObj = resData?.userPermissions;
        for (const [key, value] of Object.entries(parsedObj)) {
          userPermissions = [...userPermissions, ...value];
        }

        // if (resSuccess &&  resData?.role?.[0]?.name === 'Inspector')
        // if (resSuccess && resData?.isFree && resData?.userRole === key.defaultRoles?.Inspector) {
        //In free case, only admin will see dashboard, rest of 9 users will not see dashboard
        if (resSuccess && resData?.userRole === key.defaultRoles?.Inspector) {
          data?.setPopupmsg("User is only allowed to access mobile app");
          data?.setIsShowPopup(true);
          // SHOW_ERROR(true, "You are not authorized to access web app!");
          data?.navigate("/login");

          return {
            success: false,
            userData: {},
            token: null,
            navigate: null,
            from: null,
            permissions: {},
            userPermissions: [],
            userRole: "",
          };
        }

        // if the user email is not varified then redirect to varification page.

        if (resSuccess && resData?.emailVerifiedAt === null) {
          // console.log('aaa 1',resData?.emailVerifiedAt);
          data?.setPopupmsg("You must verify your email!");
          data?.setIsShowPopup(true);
          // SHOW_ERROR(true, "You must verify your email!");
          data?.navigate("/login");

          return {
            success: false,
            userData: {},
            token: null,
            navigate: null,
            from: null,
            permissions: {},
            userPermissions: [],
            userRole: "",
          };
        }

        if (userDataResponse.data.data.company) {
         
          getPermissions = await API.Permissions.getAllAssigned(
            userDataResponse.data.data?.roles[0]?.id
          );
        } else {
       
          getPermissions = await API.Permissions.getAllAssigned(
            userDataResponse.data.data?.roles[0]?.id
          );
        }

        return {
          success: userDataResponse.data.success,
          userData: userDataResponse.data.data,
          token: data.token,
          navigate: data.navigate,
          from: data.from,
          allRoles: userDataResponse?.data?.data?.roles,
          permissions: getPermissions?.data?.data || {},
          userPermissions: userPermissions,
          userRole: resData?.userRole,
        };
      }
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
        thunkAPI.dispatch(logoutUser());
        data.navigate("/login");
        SHOW_ERROR(true, "please login again !");
      }

      return {
        success: false,
        userData: {},
        token: null,
        navigate: null,
        from: null,
        permissions: {},
        userPermissions: [],
        userRole: "",
      };
    }
  }
);

export const authLogIn = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const res = await API.auth.login(userData.loginData);
      const { access_token } = res.data;
      if (access_token) {
        // userData.navigate(userData.from);
        axios.defaults.headers.common = {
          Authorization: `Bearer ${access_token}`,
        };
        thunkAPI.dispatch(
          getUserData({
            token: access_token,
            navigate: userData.navigate,
            from: userData.from,
            setIsShowPopup: userData?.setIsShowPopup,
            setPopupmsg: userData?.setPopupmsg,
          })
        );

        /******** Get total Number of Users **********/
        // const r = await API.Users.getAll({
        //   companyId: this.getCompanyId
        // });
        //
        // console.log("allUsers", r?.data?.length);
        /******** Get total Number of Users **********/

        return {
          success: true,
          data: res.data,
          code: 200,
        };
      }
    } catch (error) {
      const { code, success, message, errors, data } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        if (data?.is_expired) {
          userData?.navigate("/register");
          SHOW_ERROR(true, message);
          return {
            success,
            data: { isFreeExpired: true },
            code,
          };
        } else {
          SHOW_ERROR(true, ERROR_MESS);
        }
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getAllRoles = createAsyncThunk("auth/all-roles", async () => {
  try {
    const res = await API.auth.getallRoles();
    return res.data;
  } catch (error) {
    return error.response.data;
  }
});
export const getCompanyId = createAsyncThunk("get/companyId", async (id) => {
  const response = id;
  return response;
});

export const passwordReset = createAsyncThunk(
  "auth/passwordReset",
  async (data, thunkAPI) => {
    try {
      data?.setIsLoading(true);
      const state = thunkAPI.getState();
      const res = await API.auth.passwordUpdate(data?.values);
      if (res.data.success) {
        data?.setIsLoading(false);
        data?.handleClose();
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        if (state?.Auth?.currentUser?.email === res.data?.data?.email) {
          thunkAPI.dispatch(logoutUser());
          data?.navigate("/login", { state: { authKey: "log-out" } });
        }
      }

      return res.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;
      data?.setIsLoading(false);

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty("password")
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      return error.res;
    }
  }
);

export const regPage = createAsyncThunk("auth/regPage", async (data) => {
  //   try {
  //     const res = await API.auth.forgotPassword(data.mail);
  //     return data.mail;
  //   } catch (error) {
  //     const { code, success, message, errors } = error.response.data;
  //     if (!success && code === 400) {
  //       SHOW_ERROR(true, message);
  //     } else if (
  //       !success &&
  //       code === 422 &&
  //       errors.hasOwnProperty('password')
  //     ) {
  //       SHOW_ERROR(true, errors.password[0]);
  //     } else {
  //       SHOW_ERROR(true, message);
  //     }
  //     return error.res;
  //   }
});
export const forgotPassowrd = createAsyncThunk(
  "auth/forgotPassword",
  async (data) => {
    try {
      await API.auth.forgotPassword(data.mail);
      return { email: data?.mail?.email, code: 200 };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty("password")
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      return { code, success, message, errors };
    }
  }
);

export const verifyResetCode = createAsyncThunk(
  "auth/verisyCode",
  async (data) => {
    try {
      const res = await API.auth.verifyResetCode(data.code);
      if (res.data.success) {
        data.navigate("/newPass");
      }
      return res.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422 && errors.hasOwnProperty("code")) {
        SHOW_ERROR(true, errors?.code[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      return { code, success, message, errors };
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    try {
      const res = await API.auth.resetPassword(data.pass);
      if (res.data.success) {
        toast.success("Password Reset Successfully. Please Re-login!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      thunkAPI.dispatch(logoutUser());
      return { res: res.data, navigate: data?.navigate };
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty("password")
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      return error.res;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCompnyId: {
      reducer(state, action) {
        state.companyId = action.payload;
      },
    },
    logoutUser: {
      reducer(state, action) {
        state.Authenticate = false;
        state.isFreeRegister = false;
        state.status = "done";
        state.currentUser = {};
        state.permissions = [];
        removeToken();
        removeCompantId();
      },
    },
    updateUserInfo: {
      reducer(state, action) {
        if(action.payload.username) {
          state.currentUser.username = action.payload.username;
        }
        if(action.payload.img) {
          state.currentUser.image = action.payload.img;
        }
        // state.currentUser.username = "abc123";
      },
    },
    updateSideBar: {
      reducer(state, action) {
        state.sideBarStatus = action.payload.sidebarstatus;
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authLogIn.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(authLogIn.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (data?.isFreeExpired) {
          state.isFreeExpired = data.isFreeExpired;
        }

        if (success && code === 200) {
          // state.status = "succeeded";
          if (data.access_token) {
            setToken(data.access_token);
          }
          if (data?.companyId) {
            setCompantId(data.companyId);
          }
        } else {
          state.status = "failed";
        }
      })
      .addCase(authLogIn.rejected, (state, action) => {
        state.status = "failed";
        SHOW_ERROR(true, "please check your internet connection");
      })
      .addCase(getUserData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        const { success, userData } = action.payload;
        if (success) {
          state.status = "succeeded";
          if (action.payload.token) {
            setToken(action.payload.token);
          }
          if (action.payload.userData.company) {
            setCompantId(action.payload.userData.company);
          }
          if (
            action.payload.from === "/login" ||
            action.payload.from === undefined
          ) {
            if (!action.payload.userData.company) {
              action.payload?.navigate("/company");
              // state.navigation = '/company';
              // navigation('/company')
            } else {
              if (userData?.isFree) {
                state.isFreeRegister = true;
              }
              if (!userData?.is_password_updated) {
                action.payload?.navigate("/newPass");
                state.isFirsLogin = true;
                state.userEmail = action?.payload?.userData?.email;
              } else {
                action.payload?.navigate("/");
              }
              // state.navigation = '/';
              // navigation('/')
            }
          } else {
            action.payload?.navigate(action.payload?.from);
            if (userData?.isFree) {
              state.isFreeRegister = true;
            }
          }
          state.currentUser = action.payload?.userData;

          if (
            action.payload?.userData &&
            action.payload?.permissions?.assignedPermissions !== undefined
          ) {
            for (const [key, value] of Object.entries(
              action.payload?.permissions?.assignedPermissions
            )) {
              state.permissions.push(value?.trim());
            }
          }
          state.userPermissions = action?.payload.userPermissions;
          state.userRole = action?.payload?.userRole;
          state.Authenticate = true;
        } else {
          state.status = "failed";
          state.Authenticate = false;
        }
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.status = "failed";
        // SHOW_ERROR(true, "please check your internet connection");
        // state.error = action.payload?.message;
      })

      .addCase(getAllRoles.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        if (success) {
          state.status = "succeeded";
          state.allRoles = data;
        }
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(passwordReset.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(passwordReset.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(getCompanyId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCompanyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companyId = action.payload;
      })
      .addCase(getCompanyId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(forgotPassowrd.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(forgotPassowrd.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { payload } = action;
        if (payload?.code >= 400) {
          state.check = true;
        } else {
          state.email = payload?.email;
          state.check = false;
        }
        // state.check = false;
      })
      .addCase(forgotPassowrd.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase("RESET_CHECK", (state, action) => {
        state.check = true;
      })
      .addCase("UPDATE_USERNAME", (state, action) => {
        state.currentUser.username = action?.payload;
      })
      .addCase(verifyResetCode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyResetCode.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload?.code >= 400) {
          state.check = false;
        } else {
          state.check = true;
        }
        state.status = "succeeded";
      })
      .addCase(verifyResetCode.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (state.isFirsLogin) {
          state.isFirsLogin = false;
        }
        action?.payload?.navigate("/login", { state: { authKey: "log-out" } });
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { logoutUser, setCompnyId, updateUserInfo, updateSideBar } = authSlice.actions;

const { reducer } = authSlice;
export default reducer;
