import React, {useEffect, useLayoutEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { AiOutlineUser } from 'react-icons/ai';
import { BsPower, BsUnlockFill } from "react-icons/bs";
import { logoutUser, passwordReset, updateUserInfo, updateSideBar } from "../../../Redux/features/AuthSlice";
import { totalUsersUpdate } from "../../../Redux/features/StripeSlice";
import { getToken } from "../../../utils";
import SoloFormLoading from "../../common/SkeltonLoading/SoloFormLoading";
import PasswordUpdate from "./PasswordUpdate";
import Dropdown from "react-bootstrap/Dropdown";
import { fetchOneCompanyData } from "../../../Redux/features/CompanyManagementSlice";
import { BiUser } from "react-icons/bi";
import { API } from "../../../config";
import { BsFillBellFill } from "react-icons/bs";
import moment from "moment/moment";
import Button from "@mui/material/Button";
import { Icon } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Pusher from "pusher-js";
const Header = ({ sideBarToggle, setSideBarToggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    Auth: { Authenticate, currentUser, status },
    CompanyManagement: { company },
  } = useSelector((state) => state);

  const { isFreeRegister, sideBarStatus } = useSelector((state) => state.Auth);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [eventData, setEventData] = React.useState([]);
  const [userName, setUserName] = React.useState(currentUser?.username);

  const handleOpen = () => setModelOpen(true);
  const handleClose = () => setModelOpen(false);
  const handlePopup = () => {
    dispatch({ type: "SHOW_UPGRADE_TO_STANDARD_POPUP", action: null });
  };


  const logOut = () => {
    dispatch(logoutUser());
    navigate("/login", { state: { authKey: "log-out" } });
  };

  // dispatch(updateUserInfo());
  // dispatch(updateUserInfo({username:"abc"}));
  // console.log("userinfo", currentUser);

  useEffect(() => {
    // dispatch(updateUserInfo({state: [...state], payload:{username:"abc"}}));
    setEventData(currentUser?.notifications);
    // Create a new instance of Pusher
    const pusher = new Pusher("3f2e5ae628ca52fd09af", {
      cluster: "ap2",
      // Additional options if needed
    });

    // Subscribe to a channel
    const channel = pusher.subscribe("chat-user");

    console.log("asc9y9c9a", channel);
    // Bind to an event on the channel
    channel.bind("chat-event", function (data) {
      console.log("checkFormsgscoket", data);
      //setEventData((prevData) => [...prevData, { notification_message: data }]);
    });

    // Clean up subscription when the component unmounts
    return () => {
      channel.unbind("chat-user");
      pusher.unsubscribe("chat-event");
    };
  }, []);

  useEffect(() => {
    const company = localStorage.getItem("companyId");
    setEventData(currentUser?.notifications);
    if (company) {
      dispatch(fetchOneCompanyData(company));
    }
    // //console.log("company", company);

    /******** Get total Number of Users **********/
    (async () => {
      const res = await API.Users.getAll({ key:"", value:"" });
      // console.log("allUsers", res?.data?.data?.length);
      dispatch(totalUsersUpdate({ noOfUsers: res?.data?.data?.length}));
    })();
    /******** Get total Number of Users **********/
  }, [dispatch]);

  const notificationCount = eventData?.filter((el) =>
    el?.notification_message?.includes("inspection lot")
  ).length;

 
  
  const getNewUsername = async () => {
    try {
      let data = {"id": currentUser.id};
      const response = await API.auth.user(getToken());
      setUserName(response?.data?.data?.username);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <nav
      className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="container-fluid">
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <Link
            className="nav-item nav-link px-0 me-xl-4"
            to="#"
            data-bs-toggle="collapse"
            data-bs-target="#layout-menu"
          >
            {sideBarToggle && (
              <i
                className="bx bx-menu bx-sm"
                onClick={() => setSideBarToggle(false)}
              />
            )}
          </Link>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <div className="toggel-menu-icon">
            <Link
              to="#"
              className="layout-menu-toggle menu-link text-large ms-auto"
            >
              {sideBarStatus ? (
                <Icon onClick={() => {dispatch(updateSideBar({sidebarstatus: false}))}}>
                  <ArrowBackIcon />
                </Icon>
              ) : (
                <Icon onClick={() => {dispatch(updateSideBar({sidebarstatus: true}))}}>
                  <ArrowForwardIcon />
                </Icon>
                )}
            </Link>
          </div>
          
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            {isFreeRegister ? (
              // <p
              //   className="m-0 pointer text-primary"
              //   style={{ fontSize: "16px" }}
              //   onClick={handlePopup}
              // >
              //   Upgrade to Standard
              // </p>
              <button
                type="button"
                className="btn btn-primary"
                style={{ fontSize: "16px" }}
                onClick={handlePopup}
              >
                Upgrade to Standard
              </button>
            ) : null}
            {/* Notification  */}
            <Dropdown>
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <Dropdown.Toggle id="dropdown" variant="none">
                  {/* <Link
                    className="nav-link dropdown-toggle hide-arrow"
                    to="#"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                  >
                    <div className="">
                      {/* <img
                        src="\assets\img\avatar2.png"
                        alt="avatar"
                        className="rounded-circle"
                      /> */}

                  <BsFillBellFill className="rounded-circle" size={25} />
                  {notificationCount !== 0 ? (
                    <span
                      className="position-absolute d-flex align-items-center justify-content-center badge-secondary rounded-circle bg-danger text-white px-1"
                      style={{
                        minWidth: "20px",
                        minHeight: "20px",
                        top: 0,
                        right: 13,
                        fontSize: "12px",
                      }}
                    >
                      {notificationCount}
                    </span>
                  ) : null}
                  {/* </div>
                  </Link> */}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="notification-dropdown py-0"
                  variant="light"
                >
                  <h4
                    className="text-center text-white py-1 mb-0 position-sticky top-0"
                    style={{ background: "#3e6dbb" }}
                  >
                    Notifications
                  </h4>

                  {eventData?.map((notify, index) => {
                    if (
                      notify?.notification_message?.includes("inspection lot")
                    ) {
                      return (
                        <li className=" py-3 dropdown-item d-flex align-items-center justify-content-between border-bottom">
                          <div className="d-flex align-items-center">
                            <BiUser className="me-2" size={"1.5rem"} />
                            <p className="mb-0">
                              {/*  {eventData} */}
                              {notify?.notification_message}
                            </p>
                          </div>
                          <p className="ms-3 mb-0 text-muted font-weight-light">
                            <small>
                              {moment(notify?.created_at).startOf().fromNow()}
                            </small>
                          </p>
                        </li>
                      );
                    }
                  })}

                  {notificationCount === 0 ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "calc(100% - 33px)" }}
                    >
                      <p className="text-center mt-2">No notification</p>
                    </div>
                  ) : null}
                </Dropdown.Menu>
              </li>
            </Dropdown>
            {/* User */}
            <Dropdown>
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <Dropdown.Toggle id="dropdown" variant="none">
                  {/* <Link
                    className="nav-link dropdown-toggle hide-arrow"
                    to="#"
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                  > */}
                  <div className="avatar avatar-online ">
                    <img
                      src={
                        currentUser?.image
                          ? currentUser?.image
                          : "/assets/img/avatar2.png"
                      }
                      alt="profile"
                      className="rounded-circle"
                      onClick={() => getNewUsername()}
                    />
                  </div>
                  {/* </Link> */}
                </Dropdown.Toggle>

                <Dropdown.Menu variant="light">
                  <div>
                    <li>
                      {Authenticate && status !== "loading" ? (
                        // <Link
                        //   to={`/user/edit/${currentUser?.id}`}
                        //   className="dropdown-item"
                        // >
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3 ms-3">
                            <div
                              className={`avatar ${
                                Authenticate
                                  ? " avatar-online "
                                  : " avatar-offline "
                              }`}
                            >
                              <img
                                src={
                                  currentUser?.image
                                    ? currentUser?.image
                                    : "/assets/img/avatar2.png"
                                }
                                alt="profile"
                                className="rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block lh-1">
                              {userName}(
                              <small>{currentUser?.userRole}</small>)
                            </span>
                            <small>{company?.name}</small>
                          </div>
                        </div>
                      ) : (
                        // </Link>
                        <SoloFormLoading times={[1, 2, 3]} />
                      )}
                    </li>
                    {currentUser?.company ? (
                      <>
                        <li>
                          <div className="dropdown-divider" />
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`/user/edit/${currentUser?.id}`}
                          >
                            <BiUser className="me-2" size={"1.5rem"} />
                            <span className="align-middle">My Profile</span>
                          </Link>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          handleOpen();
                        }}
                      >
                        <BsUnlockFill className="me-2" size={"1rem"} />
                        <span className="align-middle">Password Update</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider" />
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={logOut}>
                        <BsPower size={"1.2rem"} className="me-2" />
                        <span className="align-middle">Log Out</span>
                      </button>
                    </li>
                  </div>
                </Dropdown.Menu>
              </li>
            </Dropdown>
            {/*/ User */}
          </ul>
        </div>
        {/* Search Small Screens */}
        <div className="navbar-search-wrapper search-input-wrapper d-none">
          <input
            type="text"
            className="form-control search-input container-fluid border-0"
            placeholder="Search..."
            aria-label="Search..."
          />
          <i className="bx bx-x bx-sm search-toggler cursor-pointer" />
        </div>
      </div>
      <PasswordUpdate
        handleClose={handleClose}
        passFun={(restArg) => passwordReset({ ...restArg, navigate })}
        open={modelOpen}
      />
    </nav>
  );
};

export default Header;
