import React from "react";

import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

const RestrictedAcess = () => {
  const styles1 = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    "warning-container": {
      textAlign: "center",
      maxWidth: "700px",
      padding: "20px",

      borderRadius: "5px",
    },
    "warning-icon": {
      fontSize: "64px",
      color: "#ff6b6b",
      marginBottom: "10px",
    },
    h1: {
      fontSize: "32px",
      marginBottom: "10px",
      fontWeight: "600",
      lineHeight: "48px",
      color: "black",
    },
    p: {
      fontSize: "20px",
      marginBottom: "20px",

      fontWeight: "400",

      color: "black",
    },
  };


  
  const dispatch = useDispatch();
  const handlePopup = () => {
    dispatch({ type: "SHOW_UPGRADE_TO_STANDARD_POPUP", action: null });
  };
  return (
    <div style={styles1.container}>
      <div style={styles1["warning-container"]}>
        <span style={styles1["warning-icon"]}>🚫</span>
        <h1 style={styles1.h1}>Restricted Access</h1>
        <p style={styles1.p}>
          The feature you are trying to access is restricted in free version.
          Please upgrade your account to standard to access all feature of
          Process intell Thanks.
        </p>
        <Button
          onClick={() => {
            handlePopup();
          }}
          variant="contained"
        >
          Upgrade Account
        </Button>
      </div>
    </div>
  );
};

export default RestrictedAcess;
