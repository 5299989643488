import React from "react";
import ReactDOM from "react-dom/client";
import ErrorBoundary from "./components/common/ErrorBoundary";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import Store from "./Redux/Store";

import "../src/assets/css/style.css";
import "../src/site/assets/styles.css";
import "react-phone-input-2/lib/style.css";

import "./assets/css/theme-default.css";
import "./assets/css/demo.css";
import "./assets/css/perfect-scrollbar.css";
import "./assets/css/style.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
// import "./assets/fonts/flag-icons.css";
// import "./assets/fonts/fontawesome.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <BrowserRouter>
    <ErrorBoundary>
        <App />
        </ErrorBoundary>
    </BrowserRouter>
    <ToastContainer />
  </Provider>
);
