import { useDispatch, useSelector } from "react-redux";
import Filters from "./components/Filters";
import InspectionLotGraph from "./InspectionLot";
import QCPointQulitative from "./QCPointQulitative";
import CancelIcon from "@mui/icons-material/Cancel";
import DefectInspection from "./DefectInspection";
import DownloadingIcon from "@mui/icons-material/Downloading";
import Step from "./Step";
import { useNavigate } from "react-router-dom";
import {
  fetchTopFivePlans,
  fetchTopFiveInspectors,
  inspectorsForMaterial,
  topRejectedLots,
  acceptanceRejectionRate,
  inspectionLotStatusPie,
  controlLimitChart,
} from "../../../../../Redux/features/GraphsSlice";
import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import produce from "immer";
import "../../../../../assets/css/main.css";
// local imports
import { getDashboardData } from "../../../../../Redux/features/GraphsSlice";
// import NativePickers from "./DateTimePicker";
import {
  fetchInspectionPlans,
  fetchOneInspectoinPlanData,
} from "../../../../../Redux/features/InspectionPlanSlice";
import CUTable from "../../../../common/CUTable";
import CustomizedSwitches from "../../../../common/ToggleButton";
import InspectionLotChart from "../../../playground components/chart";
import { FaLock } from "react-icons/fa";
import QCControlLimit from "./QCControlLimit";
import HorizontalBarChart from "./HorizontalBarChart";
import GroupedBarChart from "./GroupedBarChart";
import { fetchAllMaterials } from "../../../../../Redux/features/MaterialMasterSlice";
import { fetchOneStep } from "../../../../../Redux/features/StepSlice";
import PieChart from "./PieChartGraph";
import PieChartGraph from "./PieChartGraph";
import { getAllUsers } from "../../../../../Redux/features/UsersSlice";
import moment from "moment";

export default function Dashboard() {
  const { isFreeRegister } = useSelector((state) => state.Auth);
  const { company } = useSelector((state) => state.CompanyManagement);
  const {
    dashboard,
    inspectionLot,
    plansWithMaxLots,
    materialTopInspectors,
    inspectorsForMaterialLot,
    topRejected,
    acceptRejectRate,
    lotStatusPie,
    controlLimit,
  } = useSelector((state) => state.Graphs);
  const inspectionPlans = useSelector((state) => state.InspectionPlan);
  const stepdata = useSelector((state) => state?.InspectionPlan?.plan?.steps);
  const qcData = useSelector((state) => state?.Step?.step?.qcPoints);
  const [plansData, setPlansData] = useState(plansWithMaxLots);
  const [inspectorsData, setInspectorsData] = useState([materialTopInspectors]);
  const [topInspectorsForMaterialData, setTopInspectorsForMaterial] = useState(
    inspectorsForMaterialLot
  );
  const [topRejectedData, setTopRejectedData] = useState(topRejected);
  const [acceptRejectData, setAcceptRejectData] = useState(acceptRejectRate);
  // const [lotStatusData, setLotStatusData] = useState(lotStatus);
  const [filterData, setFilterdata] = useState({
    material_id: 0,
    material_status: "",
  });
  const [stepAndQc, setStepandQc] = useState([]);
  const [controlLimitData, setControlLimitData] = useState({
    planId: 0,
    stepId: 0,
    qcPointId: 0,
    startDate: "",
    endDate: "",
  });
  const [qc, setQc] = useState(false);
  const [plan, setPlan] = useState(false);
  const [controlData, setControlData] = useState({
    planId: 0,
    // stepId: 0,
    // qcId: 0
  });
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [date, setDate] = useState();
  const [val, setVal] = useState();

  // console.log("qc daataa", stepdata?.[0]?.id);
  const {
    Users: { users },
    MaterialMaster: { materials },
  } = useSelector((state) => state);
  const { status } = useSelector((state) => state.BuildArea);

  const abc = useSelector((state) => state);
  console.log("acb", users);

  const [treeData, setTreeData] = useState(dashboard);
  const [currentNode, setCurrentNode] = useState({});
  const [created, setCreated] = useState(inspectionLot?.data?.pieValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { dashboard } = useSelector((state) => state.Graphs);
  const [inspectors, setInspectors] = useState([]);
  const [MTD, setMTD] = useState(true);
  const useMTD = (t) => {
    // console.log('MTD1', MTD);
    setMTD(t);
    // console.log('MTD2', MTD);
  };
  // console.log('niko', created);
  const yesterday = new Date();
  const ymm = String(yesterday.getMonth() + 1).padStart(2, "0"); //January is 0!
  const Yyyyy = yesterday.getFullYear();
  const YesterdayDate = Yyyyy + "-" + ymm;
  const [startDate, setStartDate] = useState("");
  const firstMDate = Yyyyy + "-" + ymm + "-" + "01";
  const firstYDate = Yyyyy + "-" + "01" + "-" + "01";
  useEffect(() => {
    // console.log('inspection info', dashboard);
    setStartDate(!MTD ? firstYDate : firstMDate);
  }, [MTD]);

  // useLayoutEffect(() => {
  //   dispatch(getDashboardData(YesterdayDate));
  // }, []);
  const handleToggle = useCallback((id) => {
    setTreeData(
      produce((draft) => {
        const form = draft.find((f) => f.id === "6");
        // // // //console.log(form.D);
        form.children = [
          {
            id: "9",
            label: "cellphone",
          },
        ];
      })
    );
  }, []);

  const handlPopup = () => {
    if (isFreeRegister) {
      navigate("/restricted");
      // dispatch({ type: "SHOW_UPGRADE_TO_STANDARD_POPUP", action: null });
    }
  };
  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  const handleMaterial = (e) => {
    console.log("material id", e.target.value);
    setFilterdata({
      ...filterData,
      material_id: Number(e.target.value),
    });
    handleSubmit({ material_id: Number(e.target.value) });
  };

  const handleStatus = (e) => {
    console.log("status", e.target.value);
    // setFilterdata({
    //     ...filterData,
    //     material_status: e.target.value
    // })
    handleSubmit({ ...filterData, material_status: e.target.value });
  };

  const handlePlan = (e) => {
    setControlData({
      planId: 0,
      stepId: 0,
      qcPointId: 0,
    });
    console.log("Plan id", e.target.value);
    dispatch(fetchOneInspectoinPlanData(Number(e.target.value)));
    setPlan(true);
    setControlData({
      planId: Number(e.target.value),
      // stepId: Number(stepdata?.[0]?.id),
      // qcId:   Number(stepdata?.[0]?.qcPoints?.[0]?.id)
    });
  };

  const handleSteps = (e) => {
    setQc(true);
    console.log("Step id", e.target.value);
    dispatch(fetchOneStep(Number(e.target.value)));
  };

  const handleQc = (e) => {
    console.log("Qc id", e.target.value);
  };

  const handleStartTimeChange = (date) => {
    console.log("should start function runs ");
    date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    // console.log("prev date-->", value.start_date);
    console.log("start date-->", date);
    // setTemp_Start(date);
    // setValue({ ...value, start_date: date });
    // console.log("should temp date-->", temp_start);

    // if (date) {
    //   currentdate = Date().toLocaleString();
    //   currentdate = moment(currentdate).format("YYYY-MM-DD HH:mm:ss");
    //   console.log("Current date-->", currentdate);

    //   if (date < currentdate) {
    //     console.log("should less than current date-->"),
    //       setStartDatevalidate("Start date should be after the current date"),
    //       setDateUpdate(true);
    //   }

    //   else if (date >= value.due_date) {
    //     console.log("should greator than due date-->"),
    //       setStartDatevalidate("Start date should not be after the due date"),
    //       setDateUpdate(true);
    //   } else {
    //     console.log("should in else -->"),
    //       setStartDatevalidate(""),
    //       setDateUpdate(false),
    //       setValue({ ...value, start_date: date });
    //   }
    // } else {
    //   setValue({ ...value, start_date: date });
    // }
  };

  const handleEndTimeChange = (date) => {
    // console.log(" should temp_start value", temp_start);

    // const date2 = new Date(date);
    date = moment(date).format("YYYY-MM-DD HH:mm:ss");
    console.log("date--> in end time change", date);

    // if (date2) {
    //   currentdate = Date().toLocaleString();
    //   currentdate = moment(currentdate).format("YYYY-MM-DD HH:mm:ss");
    //   setValue({ ...value, due_date: date2 });
    //   if (date2 <= value.start_date) {
    //     console.log("should special case 1");
    //     console.log("should not update");
    //     setDatevalidate(true);
    //     setDateUpdate(true);
    //   }
    //   else if (date2 <= currentdate) {
    //     console.log("should special case 3");
    //     console.log("should greator than current");
    //     setDatevalidate(true);
    //     setDateUpdate(true);
    //   }
    //   else {
    //     console.log("should special case 4");
    //     console.log("should new date-->", date2);
    //     console.log("should");
    //     setDatevalidate(false);
    //     setDateUpdate(false);
    //     if (temp_start !== "") {
    //       setStartDate();
    //     }
    //     setValue({ ...value, due_date: date2 });
    //   }
    // } else {
    //   setValue({ ...value, due_date: date2 });
    // }
  };
  const handleData = () => {
    console.log("api data", stepdata);
    // setControlData({
    //     ...controlData,
    //     stepId: Number(stepdata?.[0]?.id),
    //     qcId:   Number(stepdata?.[0]?.qcPoints?.[0]?.id)
    // })
    dispatch(
      controlLimitChart({
        ...controlData,
        stepId: stepdata?.[0]?.id,
        qcPointId: stepdata?.[0]?.qcPoints?.[0]?.id,
      })
    );
  };
  const storage = roundToTwo(dashboard?.data?.pieData?.totalStorage);

  // //console.log("stor", storage);
  useEffect(() => {
    const e = { target: { value: inspectionPlans?.plans[0]?.id } };
    // handlePlan(e);
  }, [inspectionPlans]);

  useEffect(() => {
    setInspectors(dashboard?.data?.pieData?.topInspectors);
    return () => {};
  }, [dashboard?.data?.pieData?.topInspectors]);

  const isIterable = (obj) => {
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  };
  const ins = isIterable(inspectors);

  // const insDescending = ins
  //   ? [...inspectors].sort((a, b) => (a.totalLots < b.totalLots ? 1 : -1))
  //   : [];

  // useEffect(() => {
  //     setPlansData(plansWithMaxLots);
  // },[plansWithMaxLots])

  // useEffect(() => {
  //     setInspectorsData(materialTopInspectors);
  // },[materialTopInspectors])

  // useEffect(() => {
  //     setTopInspectorsForMaterial(inspectorsForMaterialLot);
  // },[inspectorsForMaterialLot])

  // useEffect(() => {
  //     setTopRejectedData(topRejected);
  // },[topRejected])

  // useEffect(() => {
  //     setAcceptRejectData(acceptRejectRate);
  // },[acceptRejectRate])

  // useEffect(() => {
  //     setLotStatusData(lotStatus);
  // },[lotStatus])

  useLayoutEffect(() => {
    // console.log('inspection infoo', dashboard);
    // console.log('hi', inspectionLot?.data?.pieValue);
    if (!window.localStorage.getItem("companyId")) {
      window.location.replace("/company");
    }
    dispatch(getAllUsers());
    dispatch(fetchTopFivePlans());
    dispatch(fetchTopFiveInspectors());
    dispatch(inspectorsForMaterial());
    dispatch(topRejectedLots());
    dispatch(acceptanceRejectionRate());
    dispatch(inspectionLotStatusPie());
    dispatch(fetchAllMaterials());
    dispatch(fetchInspectionPlans());
  }, []);

  useEffect(() => {
    setInspectorsData(materialTopInspectors?.InspectorsName);
  }, [materialTopInspectors]);

  const handleSubmit = (filterData) => {
    console.log("filterData", filterData);
    dispatch(fetchTopFiveInspectors(filterData));
    dispatch(inspectorsForMaterial(filterData));
    dispatch(topRejectedLots(filterData));
    dispatch(acceptanceRejectionRate(filterData));
    dispatch(inspectionLotStatusPie(filterData));
  };

  return (
    <>
      <div className="content-wrapper bg-wrapper">
        <div className="row">
          <div className="col-md-3">
            <div className="mb-3">
              <label className="col-form-label my-auto" htmlFor="MaterialId">
                Material:
              </label>
              <div>
                <select
                  id="MaterialId"
                  name="materialId"
                  onChange={handleMaterial}
                  className="form-select"
                  aria-label="Select material "
                >
                  <option value="">Select</option>
                  {materials?.map((mat, idx) => (
                    <option key={idx} value={mat?.id}>
                      {`${mat?.materialNo} - ${mat?.materialText}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <label className="col-form-label my-auto" htmlFor="MaterialId">
                Status:
              </label>
              <div>
                <select
                  id="MaterialId"
                  name="materialId"
                  onChange={handleStatus}
                  className="form-select"
                  aria-label="Select material "
                >
                  <option value="All">Select</option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                  <option value="partiallyAccepted">Partially Accepted</option>
                  <option value="forReview">Under Review</option>
                  <option value="inpProgress">In Progress</option>

                  {/* {status?.map((mat, idx) => (
                            <option key={idx} value={mat?.id}>
                                {status.name}
                             {`${mat?.materialNo} - ${mat?.materialText}`} 
                            </option>
                        ))} */}
                </select>
              </div>
            </div>
          </div>
        </div>
        {/* <CustomizedSwitches useMTD={useMTD} /> */}
        {/* Content */}
        <div className="container-xxl flex-grow-1 px-0">
          <div className="row">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="card-title mb-2">Total Users</h5>
                  <div className="card sty-card cardheight">
                    <div className="card-body text-center">
                      <h2 className="fw-bolder clr-2">
                        {users?.length}/{company?.plans?.[0]?.noOfUsers}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h5 className="card-title mb-2">Total Materials</h5>
                  <div className="card sty-card cardheight">
                    <div className="card-body text-center">
                      <h2 className="fw-bolder clr-3">{materials.length}</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h5 className="card-title mb-2">Total Inspection Plans</h5>
                  <div className="card sty-card cardheight">
                    <div className="card-body text-center">
                      <h2 className="fw-bolder clr-2">
                        {inspectionPlans?.plans?.length}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <h5 className="card-title mb-2">Top Inspectors</h5>
              {inspectorsData?.length > 0 ? (
                inspectorsData?.map((item, index) => {
                  return (
                    <div className="row mb-2">
                      <div className="col-md-12">
                        <div className="card sty-card">
                          <div className="card-body text-center">
                            <h3 className="mb-0 fw-bolder clr-3">
                            {item == null || item == undefined 
                                ? "No Inspector Found"
                                : typeof item == 'object' ? '' : item}
                              {/* {dashboard?.data?.pieData?.totalMaterials}  */}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="card sty-card">
                      <div className="card-body text-center">
                        <h3 className="mb-0 fw-bolder clr-3">
                          {"No inspector Found"}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {inspectorsData?.map((item,index) => {     
                    return (       
                    <div className="row mb-2">
                        <div className="col-md-12">        
                            <div className="card sty-card">
                                <div className="card-body text-center">
                                    <h3 className="mb-0 fw-bolder clr-3">
                                  
                                        {item}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })} */}
            </div>
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <h5 className="card-title mb-2"> </h5>
                  <div className="h-76 last-graph sty-card position-relative">
                    {isFreeRegister ? (
                      <div
                        className="free_block_overlay position-absolute"
                        onClick={handlPopup}
                      >
                        <FaLock />
                      </div>
                    ) : null}
                    {/* {console.log("plants data",plansData)} */}
                    <HorizontalBarChart data={plansWithMaxLots} chart={1} />
                  </div>
                </div>
              </div>
            </div>

            <div className="content-wrapper" style={{ marginTop: "20px" }}>
              {/* Content */}

              <div className="container-xxl flex-grow-1 px-0">
                <div className="row">
                  {/* Latest Update */}
                  <div className="col-md-4">
                    <div className="h-100 card position-relative">
                      {isFreeRegister ? (
                        <div
                          className="free_block_overlay position-absolute"
                          onClick={handlPopup}
                        >
                          <FaLock />
                        </div>
                      ) : null}
                      <PieChartGraph data={lotStatusPie} />
                      {/* <div className="card-header d-flex justify-content-between">
                        </div> */}
                      {/* <InspectionLotChart data={lotStatus} /> */}
                      {/* <InspectionLotChart startDate={startDate} MTD={MTD} />             */}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row h-100">
                      <div className="col-md-12">
                        {/* <h5 className="card-title mb-2">
                                    Top 21 Inspectors
                                </h5> */}

                        <div className="h-100 last-graph sty-card position-relative">
                          {isFreeRegister ? (
                            <div
                              className="free_block_overlay position-absolute"
                              onClick={handlPopup}
                            >
                              <FaLock />
                            </div>
                          ) : null}
                          <HorizontalBarChart
                            data={inspectorsForMaterialLot}
                            chart={2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="graph-2 mt-4 sty-card position-relative">
              {isFreeRegister ? (
                <div
                  className="free_block_overlay position-absolute"
                  onClick={handlPopup}
                >
                  <FaLock />
                </div>
              ) : null}
              <QCPointQulitative MTD={MTD} />
            </div> */}
            {/* <div className="last-graph mt-4 sty-card position-relative">
              {isFreeRegister ? (
                <div
                  className="free_block_overlay position-absolute"
                  onClick={handlPopup}
                >
                  <FaLock />
                </div>
              ) : null}
              <DefectInspection MTD={MTD} />
            </div> */}
            {/* Rejected lot horizontal bar chart  */}
            <div className="col-md-4">
              <div className="mt-4 last-graph sty-card position-relative">
                {isFreeRegister ? (
                  <div
                    className="free_block_overlay position-absolute"
                    onClick={handlPopup}
                  >
                    <FaLock />
                  </div>
                ) : null}
                <HorizontalBarChart data={topRejected} chart={3} />
              </div>
            </div>
            <div className="col-md-8">
              <div className="mt-4 last-graph sty-card position-relative">
                {isFreeRegister ? (
                  <div
                    className="free_block_overlay position-absolute"
                    onClick={handlPopup}
                  >
                    <FaLock />
                  </div>
                ) : null}
                <GroupedBarChart data={acceptRejectRate} chart_four={1} />
              </div>
            </div>
          </div>
          {/* Qc Control limit chart */}
          <div className="row">
            <div className="col-md-3">
              <div className="mb-3">
                <label className="col-form-label my-auto" htmlFor="MaterialId">
                  Inspection Plan:
                </label>
                <div>
                  <select
                    id="MaterialId"
                    name="materialId"
                    onChange={handlePlan}
                    className="form-select"
                    aria-label="Select material "
                  >
                    <option value="All">Select</option>

                    {inspectionPlans?.plans?.map((mat, idx) => (
                      <option key={idx} value={mat?.id}>
                        {`${mat?.title}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="col-form-label my-auto" htmlFor="MaterialId">
                  Steps:
                </label>
                <div>
                  <select
                    id="MaterialId"
                    name="materialId"
                    onChange={handleSteps}
                    className="form-select"
                    aria-label="Select Steps"
                  >
                    {plan === true ? (
                      stepdata?.length > 0 ? (
                        stepdata.map((step, idx) => (
                          <option key={idx} value={step?.id}>
                            {step?.title}
                          </option>
                        ))
                      ) : (
                        <option value="">Select</option>
                      )
                    ) : (
                      <option value="">Select</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mb-3">
                <label className="col-form-label my-auto" htmlFor="MaterialId">
                  QC_Title:
                </label>
                <div>
                  <select
                    id="qcPointId"
                    name="qcPointId"
                    onChange={handleQc}
                    className="form-select"
                    aria-label="Select Qc"
                  >
                    {qc === false ? (
                      stepdata?.[0]?.qcPoints?.length > 0 ? (
                        stepdata[0]?.qcPoints?.map((step, idx) => (
                          <option key={idx} value={step?.id}>
                            {step?.title}
                          </option>
                        ))
                      ) : (
                        <option value="">Select</option>
                      )
                    ) : qcData?.length > 0 ? (
                      qcData?.map((qc, idx) => (
                        <option key={idx} value={qc?.id}>
                          {qc?.title}
                        </option>
                      ))
                    ) : (
                      <option value="">Select</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mt-4">
                <button
                  className="btn btn-primary my-3 me-4"
                  onClick={handleData}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          {/*<div className="row">
                 <div className="col-md-3">
                    <div className="mb-3">
                        <label className="col-form-label my-auto" htmlFor="MaterialId">
                            Start Time:
                        </label>
                        <NativePickers
                        prevdate={currentDate}
                        value="1"
                            getDate={(date) => {
                            setDate(date);
                            setVal(date);
                            }}
                            updatedDate={(date) => {
                            handleStartTimeChange(date);
                            }}
                        />
                    </div>
                </div> */}
          {/* <div className="col-md-3">
                    <div className="mb-3">
                        <label className="col-form-label my-auto" htmlFor="MaterialId">
                            End Time:
                        </label>
                        <NativePickers
                            prevdate={currentDate}
                            value="1"
                            getDate={(date) => {
                            setDate(date);
                            setVal(date);
                            }}
                            updatedDate={(date) => {
                            handleEndTimeChange(date);
                            }}
                        />
                    </div>
                </div> 
                
            </div> */}
          <div className="col-md-12">
            <div className="last-graph mt-4 sty-card position-relative">
              {isFreeRegister ? (
                <div
                  className="free_block_overlay position-absolute"
                  onClick={handlPopup}
                >
                  <FaLock />
                </div>
              ) : null}
              <QCControlLimit data={controlLimit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
