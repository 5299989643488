import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ImTree } from "react-icons/im";
import { FaLock } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import {
  setReportSubmenu,
  setSettingSubmenu,
} from "../../../Redux/features/AppUtilsSlice";

import "../../../assets/css/main.css";
import { key } from "../../../config";

const LinkItem = ({ link, label, icon, type, parentLabel }) => {
  const { isFreeRegister, userRole } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isBlur = false;
  //blur inner links if user role is 'report viewer'
  if (
    !isFreeRegister &&
    userRole === key.defaultRoles?.ReportViewer &&
    parentLabel !== "Report"
  ) {
    // isBlur = true
  }

  //blur inner links if user role is 'flow and forms'
  if (
    !isFreeRegister &&
    userRole === key.defaultRoles?.FormsAndFlow &&
    parentLabel !== "Forms and Flows" &&  label !== "Flow Form Trail"
  ) {
    isBlur = true;
  }
  const handleMenu = (e) => {
    if (isBlur) {
      e.preventDefault();
      return;
    }
    if (isFreeRegister) {
      if (
        parentLabel === "Dashboard" ||
        parentLabel === "Foundation" ||
        label === "Inspection Lot Report"
      ) {
        if (type === "menu") {
          dispatch(setSettingSubmenu(false));
          dispatch(setReportSubmenu(false));
        }
      } else {
        e.preventDefault();
        // setIsShowUpgradePopup(true)
        navigate("/restricted");
        //dispatch({ type: "SHOW_UPGRADE_TO_STANDARD_POPUP", action: null });
      }
    } else {
      if (type === "menu") {
        dispatch(setSettingSubmenu(false));
        dispatch(setReportSubmenu(false));
      }
    }
    window.scrollTo(0, 0);
  };

  let isHideUserLink = false;
  let isShowLockIcon = false;
  // In free case, hide user link from Setting tab
  if (
    isFreeRegister &&
    parentLabel === "Settings" &&
    (label === "Users" ||
      label === "Rejection Reason" ||
      label === "Measurement Unit")
  ) {
    isHideUserLink = true;
  }
  // In standard case, hide user link from Foundation tab
  if (
    !isFreeRegister &&
    parentLabel === "Foundation" &&
    (label === "Users" ||
      label === "Rejection Reason" ||
      label === "Measurement Unit")
  ) {
    isHideUserLink = true;
  }

  if (
    isFreeRegister &&
    parentLabel !== "Dashboard" &&
    parentLabel !== "Foundation"
  ) {
    isShowLockIcon = true;
  }

  if (isFreeRegister && label === "Inspection Lot Report") {
    isShowLockIcon = false;
  }

  return (
    <>
      {!isHideUserLink ? (
        <NavLink
          onClick={handleMenu}
          activeclassname="active"
          to={link}
          className={`menu-link ${isBlur ? "blur_text" : null}` }
          // className={({ isActive, isPending }) =>
          // `menu-link ${isBlur ? "blur_text" : null}` && isPending ? "pending" : isActive ? "active" : "" 
          // }
        >
          {icon.includes("svg") ? (
            <img
              src={icon}
              className="menu-icon tf-icons svg-icon"
              alt="sidebar menu icon"
            />
          ) : label.includes("Build Area") ? (
            <ImTree className="menu-icon tf-icons " />
          ) : (
            <i className={icon} />
          )}
          <div
            style={{
              display: `flex`,
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
            }}
          >
            <span>{label}</span>
            {isShowLockIcon ? (
              <FaLock style={{ fontSize: "11px", marginLeft: "5px" }} />
            ) : null}
            {/* {subExist && <IoIosArrowDown />} */}
          </div>
        </NavLink>
      ) : null}
    </>
  );
};

export default LinkItem;
