import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { data } from "./QCControlLimit";

const PieChartGraph = (props) => {
    console.log("Pi props", props?.data?.values);
    let array = props?.data?.values;
    let tempArray = [];

    // array?.map((item, index) => {
    //     tempArray.push(item?.replace("%", ""))
    // })
  const { isFreeRegister, currentUser } = useSelector((state) => state.Auth);
  const [pieData, setPieData] = useState({
    series: props?.data?.values? props?.data?.values : 0 , 
    // series: [12,5,34,6,43],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [],
      colors: ["#00ff00", "#e06666", "#6d9eeb", "#ffd966", "#A020F0"],
      legend: {
        show: true,
      floating: false,
      position: 'bottom',
      //   markers: {
      //     width: 12,
      //     height: 12,
      //     strokeWidth: 0,
      //     strokeColor: '#000000',
      //     fillColors: undefined,
      //     radius: 12,
      //     customHTML: undefined,
      //     onClick: undefined,
      //     offsetX: 0,
      //     offsetY: 0
      // }
      // hover: false,
      },
      plotOptions: {
        pie: {
          // donut: {
          //   labels: {
          //     show: true,
          //     name: { show: true },
          //   },
          // },
          total: {
            show: true,
          },
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  });

  useEffect (() => {

    setPieData({
        series: props?.data?.values? props?.data?.values : 0 , 
        // series: [12,5,34,6,43],
        options: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: ["Accepted", "Rejected", "In Progress", "For Review", "Partially Accepted"],
          colors: ["#00ff00", "#e06666", "#6d9eeb", "#ffd966", "#A020F0"],
          legend: {
            show: true,
          floating: false,
          position: 'bottom',
          //   markers: {
          //     width: 12,
          //     height: 12,
          //     strokeWidth: 0,
          //     strokeColor: '#000000',
          //     fillColors: undefined,
          //     radius: 12,
          //     customHTML: undefined,
          //     onClick: undefined,
          //     offsetX: 0,
          //     offsetY: 0
          // }
          // hover: false,
          },
          plotOptions: {
            pie: {
              // donut: {
              //   labels: {
              //     show: true,
              //     name: { show: true },
              //   },
              // },
              total: {
                show: true,
              },
            },
          },
          // responsive: [
          //   {
          //     breakpoint: 480,
          //     options: {
          //       chart: {
          //         width: 200,
          //       },
          //       legend: {
          //         position: "bottom",
          //       },
          //     },
          //   },
          // ],
        },
      });
  },[props])
//   useEffect(() => {
//     setPieData({
//       ...pieData,
//       series: Object.values(dashboard)
//         .map((item) => item)
//         .slice(0, -1),
//       options: {
//         ...pieData?.options,
//         labels: Object.keys(dashboard)
//           .map((item) => item)
//           .slice(0, -1),
//         // colors: inspectionLot?.data?.pieColors,
//       },
//     });
//   }, [dashboard]);

  return (
    // <div style={{ height: "300px" }}>
    <div className="row align-items-center">
        <div className="col-md-12">
            <div className="d-flex justify-content-around">
                <h5
                className="fs-3 mt-3 ms-3"
                style={{ fontFamily: 'Rubik, sans-serif' }}
                >
                    Inspection Lots : All Materials
                </h5>
            </div>
        </div>
        {console.log("pie series",pieData?.series)}
      {pieData?.series?.length ? (
        <div className="col-md-12 text-center" id="chart">
        <ReactApexChart
          options={pieData?.options}
          series={pieData?.series}
          type="pie"
          width="100%"
          height="400"
        />
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "85%" }}
        >
            {isFreeRegister ? null : (
                <h2>No Results Found</h2>
            )}
        </div>
      )} 
    </div>
  );
};

export default PieChartGraph;